<template>
  <div>
    <div class="columns">
      <div class="column">
        <p class="title has-text-grey-light is-6">Mileage history</p>
      </div>
      <div class="column is-narrow">
        <p class="title is-6">
          <span class="has-text-success" v-if="!count">None</span>
        </p>
      </div>
    </div>
    <div class="box">
      <div v-if="mileage && count" class="columns">
        <div class="column">
          <p class="has-text-grey-light">Input mileage</p>
          <p
            class="subtitle is-4"
            :class="{ 'has-text-danger': anomaly(data.inputMileage, 0, true) }"
          >
            <span>{{ formatNumber(mileage) }}</span>
            <span
              class="icon"
              title="Anomaly"
              v-if="anomaly(data.inputMileage, 0, true)"
              v-tippy="{
                placement: 'right',
                trigger: 'click',
                sticky: true,
                hideOnClick: false,
                showOnLoad: true
              }"
            >
              <i class="fal fa-exclamation-triangle" />
            </span>
          </p>
        </div>
      </div>
      <table class="table is-fullwidth is-striped" v-if="count">
        <thead>
          <tr>
            <td>Mileage</td>
            <td>Date</td>
            <td>Source</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td :class="{ 'has-text-danger': anomaly(item.mileage, index) }">
              {{ formatNumber(item.mileage) }}
              <span
                class="icon"
                title="Anomaly"
                v-if="anomaly(item.mileage, index)"
                v-tippy="{
                  placement: 'right',
                  trigger: 'click',
                  sticky: true,
                  hideOnClick: false,
                  showOnLoad: true
                }"
              >
                <i class="fal fa-exclamation-triangle" />
              </span>
            </td>
            <td>
              {{ formatDate(item.dateOfInformation) }}
            </td>
            <td>{{ item.sourceOfMileage }} - {{ item.subSourceOfMileage }}</td>
          </tr>
        </tbody>
      </table>
      <div v-else-if="mileageData && !count">
        <p>
          Mileage data not available, you have not been charged for this request
        </p>
      </div>
      <div v-else-if="!mileageData">
        <p>Mileage data not requested</p>
      </div>
    </div>
  </div>
</template>

<script>
import { formatNumber } from 'accounting'
import { mileageAnomaly } from '../services'
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckMileage',
  props: {
    data: {
      type: Object,
      required: true
    },
    mileageData: {
      type: Number,
      required: true
    },
    mileage: {
      type: Number,
      required: false,
      default: () => null
    }
  },
  computed: {
    count() {
      return this.data.mileageItemsCount
    },
    items() {
      return this.data.mileageItems
    }
  },
  methods: {
    formatDate,
    anomaly(mileage, index, userInput) {
      return mileageAnomaly(mileage, index, this.items, userInput)
    },
    formatNumber(value) {
      return formatNumber(value)
    }
  }
}
</script>
