var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns"},[_vm._m(0),_c('div',{staticClass:"column is-narrow"},[_c('p',{staticClass:"title is-6"},[(!_vm.count)?_c('span',{staticClass:"has-text-success"},[_vm._v("None")]):_vm._e()])])]),_c('div',{staticClass:"box"},[(_vm.mileage && _vm.count)?_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('p',{staticClass:"has-text-grey-light"},[_vm._v("Input mileage")]),_c('p',{staticClass:"subtitle is-4",class:{ 'has-text-danger': _vm.anomaly(_vm.data.inputMileage, 0, true) }},[_c('span',[_vm._v(_vm._s(_vm.formatNumber(_vm.mileage)))]),(_vm.anomaly(_vm.data.inputMileage, 0, true))?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
              placement: 'right',
              trigger: 'click',
              sticky: true,
              hideOnClick: false,
              showOnLoad: true
            }),expression:"{\n              placement: 'right',\n              trigger: 'click',\n              sticky: true,\n              hideOnClick: false,\n              showOnLoad: true\n            }"}],staticClass:"icon",attrs:{"title":"Anomaly"}},[_c('i',{staticClass:"fal fa-exclamation-triangle"})]):_vm._e()])])]):_vm._e(),(_vm.count)?_c('table',{staticClass:"table is-fullwidth is-striped"},[_vm._m(1),_c('tbody',_vm._l((_vm.items),function(item,index){return _c('tr',{key:index},[_c('td',{class:{ 'has-text-danger': _vm.anomaly(item.mileage, index) }},[_vm._v(" "+_vm._s(_vm.formatNumber(item.mileage))+" "),(_vm.anomaly(item.mileage, index))?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                placement: 'right',
                trigger: 'click',
                sticky: true,
                hideOnClick: false,
                showOnLoad: true
              }),expression:"{\n                placement: 'right',\n                trigger: 'click',\n                sticky: true,\n                hideOnClick: false,\n                showOnLoad: true\n              }"}],staticClass:"icon",attrs:{"title":"Anomaly"}},[_c('i',{staticClass:"fal fa-exclamation-triangle"})]):_vm._e()]),_c('td',[_vm._v(" "+_vm._s(_vm.formatDate(item.dateOfInformation))+" ")]),_c('td',[_vm._v(_vm._s(item.sourceOfMileage)+" - "+_vm._s(item.subSourceOfMileage))])])}),0)]):(_vm.mileageData && !_vm.count)?_c('div',[_c('p',[_vm._v(" Mileage data not available, you have not been charged for this request ")])]):(!_vm.mileageData)?_c('div',[_c('p',[_vm._v("Mileage data not requested")])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column"},[_c('p',{staticClass:"title has-text-grey-light is-6"},[_vm._v("Mileage history")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('td',[_vm._v("Mileage")]),_c('td',[_vm._v("Date")]),_c('td',[_vm._v("Source")])])])
}]

export { render, staticRenderFns }